.navbar {
    padding: 15px 0 !important;
}
.navbar-brand {
    font-weight: bold;
    color: #fff !important;
}
.nav-link {
    color: #fff !important;
    transition: 0.4s !important;
    -webkit-transition: 0.4s !important;
    -moz-transition: 0.4s !important;
    -ms-transition: 0.4s !important;
    -o-transition: 0.4s !important;
}

.nav-link:hover {
    transform: translateY(-1px) ;
    -webkit-transform: translateY(-1px) ;
    -moz-transform: translateY(-1px) ;
    -ms-transform: translateY(-1px) ;
    -o-transform: translateY(-1px) ;
}

.navbar-nav {
    text-align: center;
    width: 100%;
    justify-content: end;
    align-items: center;
}

.navbar-toggler-icon {
    background-image: url("../../assets/menu.png") !important;
    transform: translateY(5px) ;
}
.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: none !important;
}
