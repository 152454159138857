.home-banner {
    background-size: cover ;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
}

.home-banner::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background-color: #221377;
    opacity: 0.3;
}

.home-banner .desc {
    color: #dee6ff !important;
    font-weight: 500;
}