@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");


// $px-dark: #111111 !default;
// $px-dark-80: #333333 !default;
// $px-dark-50: #4F4F4F !default;
// $px-dark-30: #828282 !default;
// $px-dark-20: #BDBDBD !default;
// $px-white: #fff !default;
// $px-black: #000 !default;

$px-dark: #051d38 !default;
$px-dark-80: #8f7cbe !default;
$px-dark-50: #bca7f1 !default;
$px-dark-30: #8c6fd6 !default;
$px-dark-hover: #3c2f5e !default;

$px-white: #fff !default;
$px-black: #000 !default;

$px-bg-1: #041824 !default;
$px-bg-2: #1363DF !default;
$px-gray: #f7f7ff !default;

$px-body: rgba(255, 255, 255, 0.8) !default;
$px-body-light: #555 !default;
$px-font-size: 15px !default;
$px-line-height: 1.7 !default;
$px-font: "Rubik", monospac !default;
$px-font-alt: "Rubik", serif !default;

// Breakpoints
$px-media-xsm: 567px !default;
$px-media-sm: 767px !default;
$px-media-md: 991px !default;
$px-media-lg: 1200px !default;
